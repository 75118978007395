<template>
  <!-- 详情与编辑、添加都放同一页  优惠券 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p class="fs-18 c-9 fw-7">基本信息</p>
      <el-form
        class="mt-24"
        label-width="140px"
        :model="cou_form"
        :rules="rules"
        ref="cou_form"
      >
        <el-form-item label="优惠券名称:" prop="name">
          <el-input
            class="form-input"
            placeholder="请输入优惠券名称"
            clearable
            maxlength="30"
            v-model="cou_form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="发行总数量:" prop="quantityIssued">
          <el-input-number v-model="cou_form.quantityIssued"  placeholder="请输入发行总数量" controls-position="right" :min="0" class="number-input"></el-input-number>
        </el-form-item>
        <el-form-item label="类型:" prop="couponClassifyId">
          <el-radio-group v-model="cou_form.couponClassifyId" :disabled="!flag" @change="changeClassifyId">
            <el-radio :label="1">满减券</el-radio>
            <el-radio :label="2">折扣券</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item hidden="true" label="发放方式:" prop="disbursement">
          <el-radio-group v-model="cou_form.disbursement" :disabled="!flag">
            <el-radio :label="1">主动领取</el-radio>
          </el-radio-group>
        </el-form-item>
          <el-form-item  label="是否可叠加使用:" prop="isSuperposition">
          <el-radio-group v-model="cou_form.isSuperposition" :disabled="!flag">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="使用门槛:" prop="useCondition">
          <el-radio-group v-model="cou_form.useCondition" :disabled="!flag">
            <el-radio :label="1">满金额减</el-radio>
            <el-radio :label="2">满件数减</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--满金额减 -->
        <el-form-item v-if="cou_form.useCondition==1" prop="fullMoney" @change="changeUseCondition">
          订单满
          <el-input-number v-model="cou_form.fullMoney" :disabled="!flag" controls-position="right" :min="0" class="number-input"></el-input-number>
          元使用
        </el-form-item>

        <!-- 满件数减 -->
        <el-form-item v-if="cou_form.useCondition==2" prop="fullNumber">
          订单满
            <el-input-number v-model="cou_form.fullNumber" :disabled="!flag" controls-position="right" :min="0" class="number-input"></el-input-number>
          件使用
        </el-form-item>
        <!--满减券 -->
        <el-form-item label="优惠内容:" prop="reducedAmount"  v-if="cou_form.couponClassifyId==1">
              减免
              <el-input-number v-model="cou_form.reducedAmount" :disabled="!flag" :precision="1" controls-position="right" :min="0" class="number-input"></el-input-number>
              元

        </el-form-item>
        <!-- 折扣券 -->
        <el-form-item label="优惠内容" prop="couponDiscount"  v-if="cou_form.couponClassifyId==2">
            打
            <el-input-number v-model="cou_form.couponDiscount" :disabled="!flag" controls-position="right" :min="0.1" :max="10" :precision="1" class="number-input"></el-input-number>
            折，最多优惠
            <el-input-number v-model="cou_form.highestDiscount" :disabled="!flag" controls-position="right" :min="0" class="number-input"></el-input-number>
            元（不填写则不作限制）
        </el-form-item>
      
        <el-form-item label="领券时间:" prop="receiveStartTime">
          <el-date-picker
            v-model="receiveTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getReceiveTime"
            :default-time="['00:00:00','23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="可用时间:" prop="validityType">
          <el-radio-group v-model="cou_form.validityType" :disabled="!flag" @change="changeValidityType">
            <el-radio :label="2">领取后</el-radio>
            <el-radio :label="1">固定日期</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--领取后 -->
        <el-form-item v-if="cou_form.validityType==2" prop="effectiveInterval">
          领券
          <el-input-number
          v-model="cou_form.effectiveInterval"
          :disabled="!flag"
          controls-position="right"
          :min="0"
          class="number-input">
          </el-input-number>
          （设置为0代表当即生效）天起，
          <el-input-number
          v-model="cou_form.effectiveDay"
          :disabled="!flag"
          controls-position="right"
          :min="1"
          class="number-input">
          </el-input-number> (持续可用天数)天可用
        </el-form-item>

        <!-- 固定日期 -->
        <el-form-item v-if="cou_form.validityType==1" prop="effectiveTime">
          可使用日期：
              <el-date-picker
                v-model="effectiveTime"
                :disabled="!flag"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="getDateTime"
                :default-time="['00:00:00','23:59:59']"
              ></el-date-picker>
        </el-form-item>
        <el-divider></el-divider>
        <p class="fs-18 c-9 fw-7">获取和使用规则:</p>
        <el-form-item label="领券人:" prop="activityPersonnel">
          <el-radio-group v-model="cou_form.activityPersonnel" :disabled="!flag">
            <el-radio :label="0">全部用户</el-radio>
            <el-radio :label="1">新用户</el-radio>
            <el-radio :label="2">老用户</el-radio>
            <el-radio :label="3">金卡会员(含首月折扣卡)</el-radio>
            <el-radio :label="4">铂卡会员</el-radio>
            <el-radio :label="5">钻卡会员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="每人至多领取次数:" prop="maximumNumber">
          <el-input-number v-model="cou_form.maximumNumber" :disabled="!flag" controls-position="right" :min="1"></el-input-number>
          <span class="c-9"> 填写数值最小为1</span>
        </el-form-item>
        <el-form-item label="其他限制:" prop="otherRestrictions">
            <el-checkbox  v-model="cou_form.otherRestrictions" :disabled="!flag" label="优惠券仅限原价购买时使用" :true-label="1" :false-label="0" name="otherRestrictions" @change="changeOtherSel"></el-checkbox>
        </el-form-item>
        <el-form-item label="互斥:">
            <el-checkbox  v-model="cou_form.preferentialPurchase" :disabled="!flag||cou_form.otherRestrictions==1" label="与优惠限购活动互斥" :true-label="1" :false-label="0"  name="preferentialPurchase"></el-checkbox>
            <el-checkbox  v-model="cou_form.freightReduction" :disabled="!flag||cou_form.otherRestrictions==1" label="与满减运费活动互斥"  :true-label="1" :false-label="0"  name="freightReduction"></el-checkbox>
        </el-form-item>
        <el-form-item label="使用说明:" prop="couponDetails">
          <tinymce v-model="cou_form.couponDetails" :height="300" />
        </el-form-item>
        <p class="fs-18 c-9 fw-7" v-if="isShowGoodsList">更多设置:</p>
        <el-form-item label="适用对象:" prop="applicableObjects" v-if="isShowGoodsList">
          <el-radio-group v-model="cou_form.applicableObjects" @change="changeApplicable" class="obj_radio" :disabled="!flag">
            <el-radio :label="1">【全场券】全场商品
              <el-button
              type="text"
              icon="el-icon-plus"
              v-if="cou_form.applicableObjects==1"
              @click="selectGoods(goodsList,false)"
              > 添加商品</el-button>

            </el-radio>

            <div class="goodsCodeList"  v-if="cou_form.applicableObjects==1&&goodsCodes.length>0" :title="goodsCodes.join('，')">
                已选({{goodsCodes.length}}) : {{goodsCodes.join('，')}}
            </div>
            <div class="copyButton">
              <el-button  type="text" style="color：black;margin-left:24px">选择复制对象：</el-button>
              <el-select v-model="oldCouponId" placeholder="只展示最近的20张优惠券">
                 <el-option
                   v-for="item in couponsList"
                   :key="item.couponId"
                   :label="item.name"
                   :value="item.couponId">
                 </el-option>
               </el-select>
               <el-tooltip placement="top">
                  <div slot="content">复制选中的那张优惠券的相关商品</div>
                  <el-button @click="copyGoods()" type="primary" icon="el-icon-copy-document" class="m-12">复制选中优惠券的商品</el-button>
              </el-tooltip>
            </div>
            
            <!-- <el-radio :label="2">【单品券】指定商品
              <el-button
              type="text"
              icon="el-icon-plus"
              v-if="cou_form.applicableObjects==2"
              @click="selectGoods(goodsList,false)"
              > 添加商品</el-button>
              <el-button
              type="text"
              v-if="cou_form.applicableObjects==2"
              @click="importGoods()"
              > 导入商品</el-button>
            </el-radio>
            <div class="goodsCodeList"  v-if="cou_form.applicableObjects==2&&goodsCodes.length>0" :title="goodsCodes.join('，')">
                已选({{goodsCodes.length}}) : {{goodsCodes.join('，')}}
            </div>
            <el-radio :label="3">【品类券】指定品类
              <el-button
              type="text"
              icon="el-icon-plus"
              v-if="cou_form.applicableObjects==3"
              @click="selectGoods(goodsList,true)"
              > 添加品类</el-button>
            </el-radio>
            <div class="goodsCodeList"  v-if="cou_form.applicableObjects==3&&goodsCodes.length>0" :title="goodsCodes.join('，')">
                已选({{goodsCodes.length}}) : {{goodsCodes.join('，')}}
            </div> -->
          </el-radio-group>

        </el-form-item>
      </el-form>
      <!-- 关联商品展示区 -->
      <div v-if="isShowGoodsList">
        <el-form ref="form" v-model="searchGoodlist" label-width="80px">
          <el-form-item label="商品编码" class="w-20">
            <el-input v-model.trim="searchGoodlist.goodsCode" clearable placeholder="多个编码以半角逗号间隔"></el-input>
          </el-form-item>
          
          <el-form-item label="门店名称" class="w-20">
            <el-select v-model="searchGoodlist.storeId" placeholder="请选择门店">
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :label="item.storeName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品名称" class="w-20">
            <el-input v-model.trim="searchGoodlist.name" clearable placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item class="w-20">
            <el-button @click="searchingGoods" type="primary">查询</el-button>
            <el-button @click="resetsGoods" type="primary">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table
          :data="goodsList"
          style="width: 100%">
          <el-table-column prop="secondClassifyName" label="操作">
            <template slot="header" slot-scope="scope">
                <el-checkbox v-model="allChecked" @change="AllcheckedChange()"  :indeterminate="isIndeterminate"></el-checkbox>
                <i class="el-icon-delete" style="margin-left:30px;color:#F56C6C" @click="batchDelete"></i>
            </template>
            <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="checkedGoods(scope.row)"></el-checkbox>
                <i class="el-icon-delete" style="margin-left:30px;color:#F56C6C" @click="batchDelete"></i>
            </template>
          </el-table-column>
           <el-table-column prop="goodsId" label="商品ID" width="80"></el-table-column>
          <el-table-column prop="goodsCode" label="商品编码" width="80"></el-table-column>
          <!-- <el-table-column label="商品图片" width="100">
            <template slot-scope="scope">
              <img class="tableImg" :src="scope.row.goodsImgs"/>
            </template>
          </el-table-column> -->
          <el-table-column prop="goodsName" label="商品名称" width="400"></el-table-column>
          <el-table-column prop="price"  label="商品价格"></el-table-column>
          <el-table-column label="商品类型">
            <template slot-scope="scope">
              {{getGoodsType(scope.row.goodsType)}}
            </template>
          </el-table-column>
          <el-table-column prop="storeName" label="门店名称" width="180"></el-table-column>
          <el-table-column prop="merchantName" label="商户名称"></el-table-column>
          <el-table-column prop="firstClassifyName" label="一级分类"></el-table-column>
          <el-table-column prop="secondClassifyName" label="二级分类"></el-table-column>
          
       </el-table>
       <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[50,100, 200, 300, 400]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalGoods">
       </el-pagination>
      </div>
      <!-- 按钮区域 -->
      <div class="flx-row ali-c js-fe mt-50">
        <el-button plain class="form-button" @click="$router.go(-1)"
          >取消</el-button
        >
        <el-button class="form-button" type="primary" @click="submit"
          >确定</el-button
        >
      </div>
      <!-- 活动商品时  点击添加商品时的弹框  选中商品券时  @getTableList="getTableList" -->
      <!-- <DepartmentGoodsDialog
        ref="GoodsDialog"
        :hiddenSelectedStore="true"
        :tableHeader="tableHeader"
        :searchArr="searchArr"
        :storeId="String(cou_form.storeId)"
        :selectArr="afferentArr"
        @getSelectedList="getSelectedList"
      ></DepartmentGoodsDialog> -->

      
      <!-- 添加商品弹窗组件 -->
      <!-- <GoodsCouponsDialog ref="GoodsCouponsDialog"
      :classifyAll="selectGoodsOrClass"
      @getGoodsList="getGoodsList"
      >
      </GoodsCouponsDialog> -->
      <ImportConponsGoods
      ref="ImportConponsGoods"
      @uploadGoods="getUploadGoods">
      </ImportConponsGoods>
      <ThirdLevelCouponsDialog
       ref="ThirdLevelCouponsDialog" @getGoodsList ="getGoodsList" :couponId='Number(id)'>
      </ThirdLevelCouponsDialog>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import UploadPics from "@/components/UploadPics";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";//未使用
import GoodsCouponsDialog from "@/components/GoodsCouponsDialog";
import ImportConponsGoods from "@/components/ImportConponsGoods";
import ThirdLevelCouponsDialog from "@/components/thirdLevelCouponsDialog";
import {
  subCouponsForm,
  getCouponDetail,//根据ID获取优惠券信息
  getCouponClassify,
  getGoodsByCouponId,//根据ID获取优惠券关联商品
  delCouponGoodsByIds,//批量删除优惠券关联商品
  saveGoodsCouponRelation,
  copyLrCouponRelation,
  getCouponList
} from "@/api/maketing/coupons";
import {
  getStoreList,
  getProcudtList,
} from "@/api/online_department_store/commodity_manage";
import { getMerchantsList } from "@/api/repast/commodity_manage"; //查询餐饮类别下的商户 根据门店id
import { getMerchantsListByStoreId } from "@/api/online_department_store/commodity_manage"; //门店下的商户信息(不分页）
export default {
  components: {
    Tinymce,
    UploadPics,
    DepartmentGoodsDialog,
    GoodsCouponsDialog,
    ImportConponsGoods,
    ThirdLevelCouponsDialog,
  },
  data() {
    return {
      cou_form: {
        name: "", // 名称
        type: 1,
        quantityIssued: 0, //发行总数量
        couponClassifyId: 1, //优惠券分类 1:满减券 2:折扣券
        isSuperposition: 0, //是否可叠加使用:0默认不可,1,可以
        useCondition: 1, //使用门槛 1.满金额减  2.满件数减'
        fullMoney: undefined, //满减金额
        reducedAmount: undefined, //减免金额
        fullNumber: undefined, //满减件数
        couponDiscount: undefined, //满件数 折扣
        highestDiscount: undefined, //满件数折扣最多优惠N元
        disbursement: 1, //发放方式      1: 主动领取
        receiveStartTime: "", //领劵开始时间
        receiveEndTime: "", //领劵结束时间
        validityType: 1, //1.领取后N天有效 2：规定日期
        effectiveInterval: 0, //领取后间隔n天生效
        effectiveDay: 0, //有效期天数（持续可用天数）
        effectiveTime: "", //有效期生效日期
        deadline: "", //有效期截止日期
        activityPersonnel: 0, //领劵人  0、全部用户 1、新用户 2、老用户  3、金卡会员（含首月折扣卡）  4、铂卡会员  5、钻卡会员',
        maximumNumber: null, //最大领取数量
        otherRestrictions: "", //其他限制     默认不限制      1:优惠券仅限原价时购买
        preferentialPurchase: 0, //是否与优惠限购互斥      *  0:不互斥      *  1:互斥',
        freightReduction: 0, //是否与满减运费互斥      * 0:不互斥      * 1:互斥',
        couponDetails: "", //使用说明
        applicableObjects: 1, //适用对象      * 1:全场券      * 2:单品券      * 3:品类券',
        goodsList: [], //商品集合[goodsCode(商品编码)]
      },
      goodsList: [], //商品的集合（所有数据）
      receiveTime: [], //领券时间
      effectiveTime: [], //有限期生效日期
      rules: {
        name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
        quantityIssued: [
          { required: true, message: "品类不能为空", trigger: "change" },
        ],
        // coupon: [
        //   { required: true, message: "类型不能为空", trigger: "change" },
        // ],
        fullMoney: [{ required: true, message: "不能为空", trigger: "change" }],
        reducedAmount: [
          {
            required: true,
            message: "折扣(减免金额)不能为空",
            trigger: "change",
          },
        ],
        storeId: [
          { required: true, message: "门店不能为空", trigger: "change" },
        ],
        merchantList: [
          { required: true, message: "商户不能为空", trigger: "change" },
        ],
        picture: [
          { required: true, message: "优惠券图片不能为空", trigger: "change" },
        ],
        suitStoreType: [{ required: true }],
        suitMerchantType: [{ required: true }],
        quantityIssued: [
          { required: true, message: "发放数量不能为空", trigger: "change" },
          { type: "number", message: "只能输入数字", min: 0 },
        ],
        drawCondition: [{ required: true }],
        limitAmount: [
          { required: true, message: "每人限额不能为空", trigger: "change" },
          { type: "number", message: "只能输入数字", min: 0 },
        ],
        useCondition: [{ required: true }],
        usageMode: [{ required: true }],
        validityType: [{ required: true }],
        effectiveTime: [
          { required: true, message: "日期不能为空", trigger: "change" },
        ],
        effectiveInterval: [
          {
            required: true,
            message: "生效时间及有效天数不能为空",
            trigger: "change",
          },
        ],

        couponDiscount: [
          { required: true, message: "请输入优惠数值", trigger: "change" },
        ],
        receiveStartTime: [
          { required: true, message: "领券时间不能为空", trigger: "change" },
        ],
        activityPersonnel: [
          { required: true, message: "请选择领券人", trigger: "change" },
        ],
        maximumNumber: [
          { required: true, message: "请输入领取次数", trigger: "change" },
          { type: "number", message: "只能输入数字", min: 0 },
        ],
      },
      date: [],
      merchantIds: [],
      cateList: [], // 优惠券品类列表
      storeList: [], // 门店列表
      merchantList: [], // 商户列表
      subImgStr: "",
      flag: true,
      tableHeader: [
        // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode",
        },
        {
          value: "商品名称",
          key: "goodsName",
        },
        {
          value: "商品图片",
          key: "imgUrl",
        },

        {
          value: "所属门店",
          key: "storeName",
        },
        {
          value: "所属商户",
          key: "merchantName",
        },
        {
          value: "一级分类",
          key: "firstClassifyName",
        },
        {
          value: "二级分类",
          key: "secondClassifyName",
        },
        {
          value: "实价",
          key: "price",
        },
        {
          value: "库存",
          key: "stock",
        },
      ],
      searchArr: [
        {
          name: "goodsName",
          value: "",
          placeholder: "请输入商品名称",
        },
        {
          name: "goodsClassifyId",
          value: "",
          placeholder: "请选择商品分类",
        },
        {
          name: "merchantId",
          value: "",
          placeholder: "请选择商户",
        },
      ],
      afferentArr: [], //传到子组件  商品列表
      selectGoodsOrClass: false, //选择商品或者分类 true:分类，false：商品
      goodsCodes: [],
      searchGoodlist:{
        goodsCode:'',
        storeId:'',
        name:''
      },
      isIndeterminate:false,//checkBox的样式控制,选择但非全选的状态
      allChecked:false,
      // 底部商品分页
      currentPage:1,
      pageSize:100,
      totalGoods:0,
      goodsTypeList:[
        {
          id:1,
          name:'生鲜商品'
        },
        {
          id:2,
          name:'丽日商品'
        },
        {
          id:3,
          name:'百货商品'
        },
        {
          id:null,
          name:'线下优惠券'
        },
      ],
      addGoodsList:[],
      id:'',
      isShowGoodsList:true,
      oldCouponId:'',
      newCouponId:'',
      couponsList:'',
      addFlag:false
    };
  },
  created(){
    console.log(this.$route)
    console.log(this.$route.query)
    if (
        //false是编辑          true是创建新优惠券
        this.$route.query.addFlag == false ||
        this.$route.query.addFlag == "false"
      ) {
        // this.flag = this.$route.query.addFlag;
        let isCopy = Boolean(this.$route.query.isCopy);
        isCopy === true ? (this.flag = true) : (this.flag = false);

        this.id = this.$route.query.id;
        this.oldCouponId = this.$route.query.id;
        this.isShowGoodsList = true//默认显示商品列表区域，当为编辑的时候，才展示商品列表编辑区域
      }else{
        this.addFlag = true
        if(this.addFlag){
          this.isShowGoodsList = false
        }
        
      }

  },
  mounted() {
    this.getStores(); //进来先获取门店列表
    this.getTypeData();
    this.getcouponList()
  },
  methods: {
    getQuery() {
      this.refill(this.id);
    },
    refill(id) {
      //查询信息  回填
      getCouponDetail(id).then((res) => {
        let data = res.data.body;
        // console.log("data", data);
        let cou_form = JSON.parse(JSON.stringify(this.cou_form));
        for (let item in cou_form) cou_form[item] = data[item];
        cou_form.couponId = data.couponId;
        // console.log("cateList", this.cateList, data.couponClassifyId);
        // if (cou_form.couponClassifyId) {
        //   cou_form.couponClassifyName = this.cateList.find((ele) => {
        //     return ele.classifyId == cou_form.couponClassifyId;
        //   }).classifyName;
        // }
        this.receiveTime = [data.receiveStartTime, data.receiveEndTime];
        this.effectiveTime = [data.effectiveTime, data.deadline];
        cou_form.fullMoney = data.fullMoney; //满减金额
        (cou_form.reducedAmount = data.reducedAmount), //减免金额
          (cou_form.fullNumber = data.fullNumber), //满减件数
          (cou_form.couponDiscount = data.couponDiscount), //满件数 折扣
          (cou_form.highestDiscount = data.highestDiscount), //满件数折扣最多优惠N元
          (cou_form.disbursement = parseInt(data.disbursement));
        // let goodsCodes = [];
        // let submitGoodsData = data.goodsList.map((item) => {
        //   goodsCodes.push(item.goodsCode ? item.goodsCode : item.goodsName);
        //   return {
        //     goodsId: item.goodsId,
        //     goodsType: item.goodsType,
        //   };
        // });
        // this.goodsCodes = goodsCodes;
        // this.goodsList = data.goodsList;
        // this.cou_form.goodsList = submitGoodsData;

        this.cou_form = cou_form;
        // this.getMerchantsListHandle();
        if (this.cou_form.suitMerchantType == 2) {
          //指定商户
          this.merchantIds = cou_form.merchantList.map(
            (item) => item.merchantName
          );
        }
        if (cou_form.validityType == 2)//有效期类型，1规定日期，2领取后N天后有效
          this.effectiveTime = [
            this.cou_form.effectiveTime,
            this.cou_form.deadline,
          ];
      });
      if(!this.flag){
        this.getGoods();
      }
      
    },
    getGoods(){
      let getcoupData = {
        couponId:this.id,
        goodsCode:this.searchGoodlist.goodsCode,
        storeId:this.searchGoodlist.storeId,
        name:this.searchGoodlist.name,
        pageNum:this.currentPage,
        pageSize:this.pageSize,
        }
        getGoodsByCouponId(getcoupData).then(res=>{
        console.log(res)
        this.goodsList = res.data.body.list
        this.goodsList.map(item=>{
          this.$set(item,'checked',false)
        })
        this.totalGoods = res.data.body.total
        this.allChecked = false
        })
    },
    //优惠券品类下拉框 改变事件
    couponClassifyChange(event) {
      this.cou_form.couponClassifyId = event;
    },
    //优惠券类型切换
    couponTypeChange() {
      // console.log(this.cou_form.coupon);
      if (this.cou_form.coupon == 2) {
        this.cou_form.suitStoreType = 2;
      }
      this.cou_form.storeId = "";
      this.merchantIds = [];
      this.cou_form.merchantList = [];
    },
    //获取优惠券分类列表  适用品类
    getTypeData() {
      //getCouponClassify().then((res) => {
      //this.cateList = res.data.body.list;
      this.getQuery();
      // });
    },
    // 获取门店列表
    getStores() {
      // console.log("获取门店列表");
      getStoreList().then((res) => {
        this.$set(this, "storeList", res.data.body.list);
      });
    },
    //获取商户列表
    getMerchantsListHandle() {
      // console.log(this.cou_form.coupon);
      this.merchantIds = [];
      this.cou_form.merchantList = [];
      if (this.cou_form.coupon == 3) {
        //餐饮优惠券
        getMerchantsList(this.cou_form.storeId).then((res) => {
          // console.log(res.data.body);
          this.merchantList = res.data.body;
        });
      } else if (
        this.cou_form.coupon == 4 ||
        this.cou_form.coupon == 5 ||
        this.cou_form.coupon == 6
      ) {
        getMerchantsListByStoreId({ shopId: this.cou_form.storeId }).then(
          (res) => {
            // console.log(res.data.body);
            this.merchantList = res.data.body;
          }
        );
      }
    },
    //   获取已勾选商品
    getSelectedList(data, index) {
      // // console.log(data)
      this.cou_form.goodsList = data;
    },

    // 打开商品列表弹窗
    selectGoods(products, isGoodsOrClass) {
      this.selectGoodsOrClass = isGoodsOrClass;

      let data = this.$refs.ThirdLevelCouponsDialog;
      // this.afferentArr = products;
      data.showGoods = true;
      // data.getAllClassifyGoods();
      // data.threeSelectGoodsArr = products;
      // data.threeSelectGoodsIDGroup = products.map((item) => {
      //   return item.goodsId;
      // });
      // if(products.length>0){
      //   data.threeSelectGoodsArr = products;
      //   data.threeSelectGoodsIDGroup = products.map((item)=>{
      //     return item.goodsId
      //   })
      // }

      // data.act_form.list = products;
      //  data.selected_temp = JSON.parse(JSON.stringify(products));
      // data.getGoods(true);
    },
    // 删除已选商品
    deleteGoods(idx) {
      this.cou_form.goodsList.splice(idx, 1);
      this.$refs.GoodsDialog.selected_temp = JSON.parse(
        JSON.stringify(this.cou_form.goodsList)
      );
      this.afferentArr = JSON.parse(JSON.stringify(this.cou_form.goodsList));
    },
    getImgData(imgData, type) {
      this.cou_form.picture = imgData.subImgs;
    },
    getDateTime(data) {
      if (data) {
        this.cou_form.effectiveTime = this.effectiveTime[0];
        this.cou_form.deadline = this.effectiveTime[1];
      } else {
        this.cou_form.effectiveTime = "";
        this.cou_form.deadline = "";
      }
    },
    getReceiveTime(data) {
      if (data) {
        this.cou_form.receiveStartTime = this.receiveTime[0];
        this.cou_form.receiveEndTime = this.receiveTime[1];
      } else {
        this.cou_form.receiveStartTime = "";
        this.cou_form.receiveEndTime = "";
      }
    },
    submit() {
      this.$refs["cou_form"].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        }
        this.submitForm();
      });
    },
    merchantChange(val) {
      //处理商户数据
      let merchantList = [];
      val.forEach((item) => {
        let merchant = this.merchantList.find((i) => i.merchantId == item);
        // console.log(merchant);
        merchantList.push({
          merchantName: merchant.merchantName,
          merchantId: merchant.merchantId,
        });
      });

      this.cou_form.merchantList = merchantList;
    },
    //点击确定提交
    submitForm() {
      let data = JSON.parse(JSON.stringify(this.cou_form));

      if (!this.cou_form.highestDiscount) {
        data.highestDiscount = "";
      }
      if (!this.cou_form.couponDiscount) {
        data.couponDiscount = "";
      }
      let isCopy = Boolean(this.$route.query.isCopy);
      if (isCopy === true) {
        data.couponId = "";
      }
      subCouponsForm(data).then((res) => {
        if (res.data.code == 200) {
          console.log(res)
          console.log(this.id)
          // 新增的，接口会返回优惠些ID
          if(res.data.body.couponId){//返回优惠券ID，优惠券新增完毕，重新打开一个带有ID的编辑模式页面
            // for(let i = 0;i<this.addGoodsList.length;i++){
            //   this.addGoodsList[i].couponId = res.data.body.couponId
            // }
            this.newCouponId = res.data.body.couponId
            if(isCopy || this.addFlag){
              let copydata = {
                oldCouponId:this.oldCouponId,
                newCouponId:this.newCouponId
              }
              copyLrCouponRelation(copydata).then(res=>{
                 console.log(res)
                 this.id = this.newCouponId
                 this.flag = false
                 this.isShowGoodsList = true
                 this.$message({
                  message: '操作成功！',
                   type: 'success'
                 });
                 this.getStores(); //进来先获取门店列表
                 this.getTypeData();
                 this.refill(this.id);  
              })
            }else{
              this.id = res.data.body.couponId
              this.isShowGoodsList = true
              this.getStores(); //进来先获取门店列表
              this.getTypeData();
              this.refill(this.id);  
            }
            
          }else{
            // for(let i = 0;i<this.addGoodsList.length;i++){
            //   this.addGoodsList[i].couponId = this.id
            // }
            this.$router.go(-1);
          }
          
        }
      });
    },
    // 其他限制，选择了优惠券仅限原价购买时使用时，默认选择所有的互斥活动
    changeOtherSel(data) {
      if (data === 1) {
        this.cou_form.preferentialPurchase = 1;
        this.cou_form.freightReduction = 1;
      } else {
        this.cou_form.preferentialPurchase = 0;
        this.cou_form.freightReduction = 0;
      }
    },
    // 接收子组件传值
    getGoodsList(data) {
      //获取参与优惠的商品列表
      // let goodsList = data.goodsList; //待提交的数据
      // let goodsCodes = [];
      // let submitGoodsData = goodsList.map((item) => {
      //   goodsCodes.push(item.goodsCode ? item.goodsCode : item.goodsName);
      //   return {
      //     goodsId: item.goodsId ? item.goodsId : item.id, //百货没有goodsId
      //     goodsType: item.goodsType,
      //   };
      // });
      // this.goodsCodes = goodsCodes;
      // this.goodsList = goodsList;
      // this.cou_form.goodsList = submitGoodsData;
      console.log(data)
      this.getGoods()
      // this.addGoodsList = data
      // if(this.goodsList.length>0){
      //   let tempary = JSON.parse(JSON.stringify(this.goodsList))
      //   this.goodsList = this.goodsList.concat(data)
      // }else{
      //   this.goodsList = JSON.parse(JSON.stringify(data))
      // }
      
      // console.log(this.addGoodsList)
    },
    importGoods() {
      //导入商品
      this.$refs.ImportConponsGoods.dialogVisible = true;
      this.$refs.ImportConponsGoods.goodsList = this.cou_form.goodsList;
      this.$refs.ImportConponsGoods.mergeGoodsList = [];
    },
    getUploadGoods(data) {
      //获取上传后的商品列表
      console.log("上传后的", data);
      let goodsCodes = [];
      let submitGoodsData = data.map((item) => {
        goodsCodes.push(item.goodsCode ? item.goodsCode : item.goodsName);
        return {
          goodsId: item.goodsId,
          goodsType: item.goodsType,
        };
      });
      this.goodsCodes = goodsCodes;
      this.goodsList = data;
      this.cou_form.goodsList = submitGoodsData;
    },
    changeApplicable(id) {
      this.goodsList = [];
      this.goodsCodes = [];
      this.cou_form.goodsList = [];
    },
    changeClassifyId(id) {
      //更改优惠券类型
      if (id == 1) {
        this.cou_form.couponDiscount = undefined;
        this.cou_form.highestDiscount = undefined;
      } else {
        this.cou_form.reducedAmount = undefined;
      }
    },
    changeUseCondition(id) {
      //更改使用门槛
      if (id == 1) {
        this.cou_form.fullNumber = undefined;
      } else {
        this.cou_form.fullMoney = undefined;
      }
    },
    changeValidityType(id) {
      if (id == 1) {
        this.cou_form.effectiveInterval = undefined;
        this.cou_form.effectiveDay = undefined;
      } else {
        this.effectiveTime = [];
        this.cou_form.effectiveTime = "";
        this.cou_form.deadline = "";
      }
    },
    // 查询关联商品
    searchingGoods(){
      this.getGoods()
    },
    // 重置关联商品
    resetsGoods(){
        this.searchGoodlist.goodsCode = ''
        this.searchGoodlist.storeId=""
        this.searchGoodlist.name=""
        this.currentPage = 1
        this.pageSize = 100
        this.getGoods()
    },
    // 批量删除--------start
    // 批量选择
    checkedGoods(){
      let checkedNums = 0
      this.goodsList.map((item)=>{
        if(item.checked === true){
          ++checkedNums
        };
      })
      console.log(checkedNums)
      if(checkedNums === 0){
        this.allChecked = false
        this.isIndeterminate= false
      }else if(checkedNums === this.goodsList.length){
        this.isIndeterminate = false
        this.allChecked = true 
      }else{
        this.isIndeterminate= true
      }
      console.log(this.allChecked)
    },
    // 单选多选全选
    AllcheckedChange(){
      let checkedNums = 0
      console.log(this.goodsList.length)
      this.goodsList.map((item)=>{
        if(item.checked === false){
          ++checkedNums
        };
      })
      if(checkedNums===0 || checkedNums===this.goodsList.length){
        this.goodsList.map((item)=>{
          this.isIndeterminate = false
          item.checked=!item.checked
        })
      }else{
        this.isIndeterminate=false
        this.allChecked = true
        console.log(this.allChecked)
        this.goodsList.map((item)=>{
          item.checked= true
        })
      }
      console.log(checkedNums)
      this.$forceUpdate()
    },
    // 删除关联商品
    batchDelete(){
      this.isConfirm(this, () => {
        let idList=[]
        this.goodsList.map((item)=>{
          if(item.checked===true){
            idList.push(item.couponRelationId)
          };
        })
        console.log(idList)
        delCouponGoodsByIds(idList).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:3000
          })
          this.checked = false
          this.isIndeterminate = false
          this.pageSize = 100
          this.currentPage = 1
          this.getGoods();
        });
      });
    },
    // 批量删除-----------end
    // 分页函数-----------start
    handleSizeChange(e){
      console.log(e)
      this.pageSize = e
      console.log(this.pageSize)
      this.getGoods()
    },
    handleCurrentChange(e){
      console.log(e)
      this.currentPage = e
      console.log(this.currentPage)
      this.getGoods()
    },
    // 分页函数-------------end
    // 商品类型数字标识符转中文
    getGoodsType(e){
      for(let i = 0;i<this.goodsTypeList.length;i++){
        if(e == this.goodsTypeList[i].id){
          return this.goodsTypeList[i].name
        }
      }
    },
    // 复制商品
    copyGoods(){
      let data = {
        oldCouponId:'',
        newCouponId:''
      }
      if((this.$route.query.addFlag == false || this.$route.query.addFlag == "false") && this.flag == false){//编辑
        data.oldCouponId = this.oldCouponId
        data.newCouponId = this.id,
        copyLrCouponRelation(data).then(res=>{
        console.log(res)
        this.$message({
          message: '操作成功！',
          type: 'success'
        });
        this.getStores(); //进来先获取门店列表
        this.getTypeData();  
      })
      }else{
        this.submitForm()
      };
      
    },
    // 获取优惠券列表
    getcouponList(){
      let data ={
        pageNum: 1,
        pageSize: 20,
        name: "",
        preferentialContent:"",
        classifyId:"",
        applicableObjects:'',
        status:'',
        startTime:"",
        endTime:""
      }
      getCouponList(data).then(res=>{
        console.log(res)
        if (res.data.code == 200) {
          this.couponsList = res.data.body.list;
        }
      })
    }
  },
};
</script>
<style lang="scss" scoped>
.copyButton{
  float: left;
}
.inline {
  display: inline-block;
}
.obj_radio {
  /deep/ .el-radio {
    float: left;
    display: block;
    margin-bottom: 20px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 0;
    .el-button {
      margin-left: 20px;
    }
  }
}
.number-input {
  width: 200px;
  /deep/.el-input {
    .el-input__inner {
      text-align: left;
    }
  }
}
.inline-input {
  width: 100px;
  /deep/.el-input__inner {
    width: 100px;
  }
}
.goodsCodeList {
  font-size: 13px;
  color: #aaa;
  margin-right: 5px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.tableImg{
  width: 20px;
  height: 20px;
}
.w-20{
  width: 20%;
  float: left;
}
.m-12{
  margin: 0 24px;
}
</style>
