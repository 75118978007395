<template>
  <el-dialog :visible="showGoods" v-if="showGoods" @close="close" width="1050px" :close-on-click-modal="false">
      <!-- 搜索查询条件，默认商超商品 -->
      <el-form class="searchBox" label-width="80px">
          <el-form-item label="商品类型:" class="w-25">
              <el-select v-model="searchList.goodsType" placeholder="请选择商品类型" @change="goodsTypeChange">
                <el-option
                  v-for="item in typeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="门店名称:" class="w-25">
              <el-select v-model="searchList.storeId" placeholder="请选择门店名称">
                <el-option
                  v-for="item in storeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="第一分类:" class="w-25">
              <el-select v-model="searchList.firstClassifyId" placeholder="请选择一级分类" @change="firstClassifyIdChange">
                <el-option
                  v-for="item in classifyList"
                  :key="item.id"
                  :label="item.classifyName"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="第二分类:" class="w-25">
              <el-select v-model="searchList.classifyId" placeholder="请选择二级分类">
                <el-option
                  v-for="item in secondClassifyList"
                  :key="item.id"
                  :label="item.classifyName"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="商品编码:" class="w-25">
              <el-input v-model.trim="searchList.goodsCode" clearable></el-input>
          </el-form-item>
          <el-form-item label="商品名称:" class="w-25">
              <el-input v-model.trim="searchList.goodsName" clearable></el-input>
          </el-form-item>
          <el-form-item class="w-25">
              <el-button type="primary" @click="getTheGoodsList">查询</el-button>
              <el-button type="primary" @click="resetSearch">重置</el-button>
          </el-form-item>
      </el-form>
      <!-- 展示列 -->
      <div>
      <el-table
           max-height ="380px"
          :data="goodsList"
          style="width: 100%">
          <el-table-column  width="40">
            <template slot="header" slot-scope="scope">
                <el-checkbox v-model="allChecked" @change="AllcheckedChange()"  :indeterminate="isIndeterminate"></el-checkbox>
            </template>
            <template slot-scope="scope">
                <el-checkbox v-model="scope.row.checked" @change="checkedGoods(scope.row)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="goodsId" label="商品ID" width="80"></el-table-column>
          <el-table-column prop="goodsCode" label="商品编码" width="80"></el-table-column>
          <el-table-column prop="goodsName" label="商品名称" width="400"></el-table-column>
          <!-- <el-table-column prop="price"  label="商品价格"></el-table-column> -->
          <el-table-column label="商品类型">
            <template slot-scope="scope">
              {{getGoodsType(scope.row.goodsType)}}
            </template>
          </el-table-column>
          <el-table-column label="门店名称" width="110">
              <template slot-scope="scope">
              {{getStoreName(scope.row.storeId)}}
            </template>
          </el-table-column>
          <el-table-column prop="merchantName" label="商户名称"></el-table-column>
          <el-table-column prop="classifyId" label="二级分类"></el-table-column>
          <!-- <el-table-column prop="secondClassifyName" label="二级分类"></el-table-column> -->
       </el-table>
       <el-pagination
         class="m-24"
         @size-change="handleSizeChange"
         @current-change="handleCurrentChange"
         :current-page="currentPage"
         :page-sizes="[100, 500, 1000, 2000]"
         :page-size="pageSize"
         layout="total, sizes, prev, pager, next, jumper"
         :total="total">
       </el-pagination>
       <el-button type="primary" @click="saveGoods" class="m-24 ml-400">添加选中商品</el-button>
       <el-button type="primary" @click="close" class="m-24 ml-600">关闭页面</el-button>
      </div>
  </el-dialog>
</template>
<script>
import {queryGoodsByGoodsType,queryClassifyTreeByGoodsType,saveGoodsCouponRelation } from "@/api/maketing/coupons";//查询商品API
export default {
    props:{
        title: String,
        couponId:Number,
    },
    data(){
        return {
            typeList:[
                {id:2,name:'商超商品'},
                {id:3,name:'百货商品'},
            ],
            storeList:[
                {id:1,name:'江北店'},
                {id:2,name:'湖畔店'},
                {id:3,name:'香榭里大街店'},
                {id:4,name:'丽日生活汇'},
                {id:5,name:'河源中心城店'},
            ],
            classifyList:[],
            secondClassifyList:[],
            searchList:{
                goodsType:2,//商品类型
                goodsName:'',//商品名
                storeId:null,//门店ID
                goodsCode:'',//商品编码
                firstClassifyId:'',//第一分类
                classifyId:'',//第二分类
            },
            goodsTypeList:[
              {
                id:1,
                name:'生鲜商品'
              },
              {
                id:2,
                name:'丽日商品'
              },
              {
                id:3,
                name:'百货商品'
              },
              {
                id:null,
                name:'线下优惠券'
              },
            ], 
            goodsList:[],//商品列表
            showGoods:false,
            currentPage:1,
            total:0,
            pageSize:100, 
            isIndeterminate:false,
            allChecked:false
        }
    },
    watch:{},
    mounted(){
        this.getGoodsTypeList()
        this.getTheGoodsList()
    },
    methods:{
        // 商品类型数字标识符转中文
        getGoodsType(e){
           for(let i = 0;i<this.goodsTypeList.length;i++){
              if(e == this.goodsTypeList[i].id){
                 return this.goodsTypeList[i].name
              }
           }
        },
        // 门店ID转中文
        getStoreName(e){
            for(let i = 0;i<this.storeList.length;i++){
              if(e == this.storeList[i].id){
                 return this.storeList[i].name
              }
           }
        },
        // 分类ID转中文
        getClassifyName(e){
            for(let i = 0;i<this.classifyList.length;i++){
              if(e == this.classifyList[i].id){
                 return this.classifyList[i].classifyName
              }
           }
        },
        //商品类型切换后分类也要换 
        goodsTypeChange(){
          this.searchList.firstClassifyId = ''
          this.searchList.classifyId = ''
          this.getGoodsTypeList()
        },
        // 一级分类变化联动二级分类列表更改
        firstClassifyIdChange(e){
              this.searchList.classifyId = ''
              this.classifyList.filter(item =>{
                if(item.id == e){
                    this.secondClassifyList = JSON.parse(JSON.stringify(item.children))
                }
            })
        },
        // 分页大小Change
        handleSizeChange(e){
            console.log(e)
            this.pageSize = e
            this.getTheGoodsList()
        },
        // 页码changge
        handleCurrentChange(e){
            this.currentPage = e
            console.log(e)
            this.getTheGoodsList()
        },
        // 获取商品数据
        getTheGoodsList(){
            let data = {
                goodsType:this.searchList.goodsType,
                storeId:this.searchList.storeId,
                goodsCode:this.searchList.goodsCode,
                goodsName:this.searchList.goodsName,
                classifyId:this.searchList.classifyId,
                pageSize:this.pageSize,
                pageNum:this.currentPage,
            }
            queryGoodsByGoodsType(data).then((res)=>{
                this.goodsList = res.data.body.list
                this.total = res.data.body.total
                for(let i = 0;i<this.goodsList.length;i++){
                    this.$set(this.goodsList[i],'checked',false)
                }
                this.isIndeterminate = false
                this.allChecked = false
                console.log(this.goodsList)
            })
        },
        // 获取商品分类
        getGoodsTypeList() {
               let data = {
                 goodsType:this.searchList.goodsType
               }
               queryClassifyTreeByGoodsType(data).then((res) => {
               console.log(res)
               this.classifyList = res.data.body;
               });
             },
        // 关闭弹窗
        close() {
            this.showGoods = false;
        },
        // 批量选择
        checkedGoods(){
          let checkedNums = 0
          this.goodsList.map((item)=>{
            if(item.checked === true){
              ++checkedNums
            };
          })
          console.log(checkedNums)
          if(checkedNums === 0){
            this.allChecked = false
            this.isIndeterminate= false
          }else if(checkedNums === this.goodsList.length){
            this.isIndeterminate = false
            this.allChecked = true 
          }else{
            this.isIndeterminate= true
          }
          console.log(this.allChecked)
        },
       // 单选多选全选
        AllcheckedChange(){
          let checkedNums = 0
          console.log(this.goodsList.length)
          this.goodsList.map((item)=>{
            if(item.checked === false){
              ++checkedNums
            };
          })
          if(checkedNums===0 || checkedNums===this.goodsList.length){
            this.goodsList.map((item)=>{
              this.isIndeterminate = false
              item.checked=!item.checked
            })
          }else{
            this.isIndeterminate=false
            this.allChecked = true
            console.log(this.allChecked)
            this.goodsList.map((item)=>{
              item.checked= true
            })
          }
          console.log(checkedNums)
          this.$forceUpdate()
        },
        // 保存
        saveGoods(){
          let data = []
          for(let i = 0;i<this.goodsList.length;i++){
            if(this.goodsList[i].checked == true){
              data.push(
                {
                  couponId:'',
                  relationId:'',
                  type:'',
                  goodsType:''
                }
              )
              data[data.length-1].couponId = this.couponId
              data[data.length-1].relationId = this.goodsList[i].goodsId
              data[data.length-1].type = 1
              data[data.length-1].goodsType = this.searchList.goodsType
            }
          }
          console.log(data)
          saveGoodsCouponRelation(data).then((res)=>{
            console.log(res)
            this.$message({
            message: "操作成功",
            type: "success",
          });
          }).catch((err)=>{
            console.log(err)
          }).finally(()=>{
            this.$message({
            message: "操作成功",
            type: "success",
          });
          // this.$router.go(-1);
          this.$emit('getGoodsList',data)
          })
          this.showGoods = false
        },
        resetSearch(){
          this.searchList.goodsType = 2
          this.searchList.goodsName = ''
          this.searchList.storeId = ''
          this.searchList.goodsCode = ''
          this.searchList.firstClassifyId = ''
          this.searchList.classifyId = ''
          this.getTheGoodsList()
        }
    }
}
</script>
<style scoped>
.searchBox{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.w-25{
    width: 25%;
}
/deep/.el-table td, .el-table th{
    padding:4px 0;
}
/deep/.el-dialog{
    margin-top: 5vh !important;
}
.m-24{
    margin-top: 24px;
}
.ml-400{
    margin-left: 400px;
}
.ml-600{
    margin-left: 100px;
}
</style>