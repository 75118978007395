<template>
    <div>
        <el-dialog
        title="批量导入商品"
        :visible.sync="dialogVisible"
        width="500px"
        >
        <span>请按导入模版的字段导入活动商品，下载模版:<a style="color:#409EFF;" :href="downUlr">单品券商品导入模版.xlsx</a></span>
        <el-upload
            class="upload-demo ml-10 mt-10 mb-10"
            accept=".xls,.xlsx"
            :action="urlUp"
            :limit="1"
            :show-file-list="true"
            :on-success="handleSuccess"
            :before-upload="uploadBefore"
            ref="upload"
        >
            <el-button type="primary" plain>选择本地文件</el-button>
            <div slot="tip" class="el-upload__tip">单次上传仅限选择1个文件</div>
        </el-upload>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" :disabled="uploading" @click="submitGoods" :loading="loading">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props:{
        title: String,
    },
    data(){
        return {
            dialogVisible:false,
            urlUp:process.env.VUE_APP_BASE_API+"/customer-service/lrCoupon/importExcel",
            downUlr:process.env.VUE_APP_BASE_API+"/customer-service/lrCoupon/exportTemplate",
            uploadGoodsList:[],//上传后的商品列表
            goodsList:[],//已经添加的商品列表
            mergeGoodsList:[], //合并后的商品列表
            hasMerge:false, //判断是否需要选择合并
            uploading:true, //是否上传中
            loading:false,
        }
    },
    watch:{
        dialogVisible(val,oVal){
            this.$nextTick(()=>{
                this.$refs.upload.clearFiles();
            })
            
        }
    },
    methods:{
        handleSuccess(res, file, fileList) { //上传成功
            if(res.code){
                // this.uploadGoodsList = res.body
                this.filterData(res.body)
                this.uploading = false
                this.loading = false
                return ;
            }
            if(res.status == 500){
                this.$alert(`错误内容: ${ res.message}`, '导入错误', {
                confirmButtonText: '确定',
                type:"error"
                });
                this.$refs.upload.clearFiles()
            }
        },
        uploadBefore(){ //上传之前
            this.uploading = true
            this.loading = true
        },
        submitGoods(){
            this.dialogVisible=false
            // this.uploadGoodsList = this.mergeGoodsList;
            if(this.mergeGoodsList.length>0){
                this.$emit('uploadGoods',this.mergeGoodsList)
            }
            
        },
        filterData(data){ //筛选导入的数据 data:导入的数据
            this.$nextTick(() => {
                let hasGoods = JSON.parse(JSON.stringify(this.goodsList))
                let hasGoodsId = hasGoods.map(item => item.goodsId);
                let importGoodsId = data.map(item => item.goodsId);
                let selectArr = [];
                let hasMerge = false; //判断是否有重复的
                for(let i = 0;i<importGoodsId.length;i++){ //循环导入的商品列表
                    selectArr.push(data[i])
                        
                    if(hasGoodsId.indexOf(importGoodsId[i])!=-1){ //判断是否有重复的数据
                        hasMerge = true
                        hasGoods.splice(hasGoodsId.indexOf(importGoodsId[i]),1)
                        hasGoodsId.splice(hasGoodsId.indexOf(importGoodsId[i]),1)
                    }
                }
                // 有重复的数据后，提示是否需要覆盖
                if(hasMerge){
                    this.$confirm('导入商品数据中有部分商品已存在，是否覆盖原有商品？', '提示', {
                        confirmButtonText: '覆盖导入',
                        cancelButtonText: '取消导入',
                        type: 'warning'
                    }).then(() => {
                        this.mergeGoodsList = hasGoods.concat(selectArr);
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消导入'
                        });      
                        this.$refs.upload.clearFiles()
                    });
                }else{
                    this.mergeGoodsList = hasGoods.concat(selectArr);
                    this.$message({
                        type: 'success',
                        message: '商品列表已获取，请点击确定导入商品列表!'
                    });
                }
                
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    
</style>