<template>
    <el-dialog :visible="showGoods" v-if="showGoods" @close="close" width="1050px" :close-on-click-modal="false">
        <div class="goods_wrap">
            <!-- 选择的类别 -->
            <div class="left">
                <!-- <div class="top">
                    <el-scrollbar style="height:75px;">
                        <el-tag
                        v-for="tag in threeSelectGoodsArr"
                        :key="tag.goodsId"
                        closable
                        type="info"
                        @close="removeTag(tag.goodsId)">
                            {{tag.goodsCode?tag.goodsCode:tag.goodsName}}
                        </el-tag>
                    </el-scrollbar>
                </div> -->
            <!-- tabs 与搜索框 -->
                <div class="center">
                    <div class="ali-c js-btw" style="text-align:right">
                        <el-input
                            size="small"
                            class="form-input-150 mr-10"
                            placeholder="查询商品"
                            v-model="keyword"
                        ></el-input>
                        <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        class="ml-15"
                        @click="search"
                        >查询</el-button
                        >
                        <el-button
                        size="small"
                        icon="el-icon-refresh"
                        class="ml-15"
                        @click="refresh"
                        >刷新</el-button
                        >
                    </div>
                </div>
            <!-- 选择面板 -->
                <div class="bottom">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane v-for="(item,index) in tabsArr" :key="index" :label="item.label" :name="item.value">
                            <div class="select-wrap">
                                <div class="box first">
                                    <div class="title">一级分类</div>
                                    <el-scrollbar style="height:362px;">
                                        <ul>
                                            <li v-for="(item,idx) in tabsArr[index].goodsList" :key="item.id" :class="firstIndex === idx?'is-active':''">
                                                <div class="label" @click="showSecond(item.children,idx)">
                                                    <span>{{ item.goodsId?item.goodsName:item.classifyName }} </span>
                                                    <i v-if="item.children.length!=0" class="el-icon-arrow-right"></i>
                                                </div> 
                                            </li>
                                        </ul>
                                    </el-scrollbar>
                                </div>
                                <div class="box second">
                                    <div class="title">二级分类
                                        <!-- <span v-if="classifyAll">
                                            已选  {{}}/{{secondList.length}}
                                        </span> -->
                                    </div>
                                    <el-scrollbar style="height:362px;">
                                        <!-- <el-checkbox-group v-model="firstIdGroup" size="small" @change="changeSelect"> -->
                                            <ul>
                                                <!-- :class="secondIdGroup.indexOf(item.id)!=-1?'is-active':''" -->
                                                <li v-for="item in secondList" :key="item.id" :class="secondIdGroup.indexOf(item.id)!=-1?'is-active':''" >
                                                    <el-checkbox v-if="classifyAll"  @change="(data)=>{changeSecondSelect(data,item)}" :true-label="item.id" :false-label="-item.id"></el-checkbox> 
                                                    <div class="label" @click="showThree(item.children)">
                                                        <span> ({{item.children.length}}) {{ item.goodsId?item.goodsName:item.classifyName }}</span>
                                                        <i v-if="item.children.length!=0" class="el-icon-arrow-right"></i>
                                                    </div> 
                                                </li>
                                            </ul>
                                    </el-scrollbar>
                                </div>
                                <div class="box three">
                                    <div class="title">
                                        商品 
                                        <span>
                                            <!-- @change="(bool)=>{selectAll(bool,true)}"  -->
                                            <el-checkbox
                                                @change="selectAll" 
                                                :disabled="threeList.length===0"
                                                :indeterminate="selectAllStatus==2"
                                                v-model="isSelectAll"
                                                label="全选">
                                            </el-checkbox>
                                            
                                            <!-- <el-link @click="selectAll" :disabled="threeList.length===0" :underline="false">
                                                <svg-icon v-if="isSelectAll" icon-class="checkbox" style="font-size:18px;"/>
                                                <svg-icon v-else icon-class="checkbox-outline" style="font-size:18px;"/>
                                                全选
                                            </el-link> -->
                                            已选{{threeSelectGoodsArr.length}}件
                                        </span>
                                    </div>
                                    <!-- <el-scrollbar style="height:281px;"> -->
                                        <ul v-if="threeList.length!=0"  v-infinite-scroll="load" class="goods-list">
                                            <li v-for="(item,idx) in count" :key="threeList[idx].goodsId" :class="threeSelectGoodsIDGroup.indexOf(threeList[idx].goodsId)!=-1?'is-active':''">
                                                <el-checkbox @change="changeThreeSelect" 
                                                    :true-label="parseInt(threeList[idx].goodsId)" 
                                                    :false-label="-(threeList[idx].goodsId)"
                                                    :checked="threeSelectGoodsIDGroup.indexOf(threeList[idx].goodsId)!=-1" >
                                                    <span>{{  threeList[idx].goodsCode }}  {{ threeList[idx].goodsId?threeList[idx].goodsName:threeList[idx].classifyName }} </span>
                                                </el-checkbox>
                                            </li>
                                        </ul>
                                    <!-- </el-scrollbar> -->
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                
            </div>
            <div class="right">
                <!-- <el-input
                    placeholder="输入关键字进行过滤"
                    v-model="filterText">
                </el-input> -->
                <h3>已选择：</h3>
                 <ul class="infinite-list" v-infinite-scroll="selLoad" >
                    <li v-for="i in selCount" :key="i-1" class="infinite-list-item">
                        <span :title="threeSelectGoodsArr[i-1].goodsCode+' '+threeSelectGoodsArr[i-1].goodsId?threeSelectGoodsArr[i-1].goodsName:threeSelectGoodsArr[i-1].classifyName">
                            {{  threeSelectGoodsArr[i-1].goodsCode }}  {{ threeSelectGoodsArr[i-1].goodsId?threeSelectGoodsArr[i-1].goodsName:threeSelectGoodsArr[i-1].classifyName }}
                        </span>
                        <i class="del-icon el-icon-delete" @click="removeTag(threeSelectGoodsArr[i-1].goodsId)" ></i>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 按钮 -->
        <div class="flx-row ali-c js-fe mt-50">
            <el-button plain size="medium" @click="cancelSelect">取消</el-button>
            <el-button class="ml-15" size="medium" type="primary" @click="submitGoods">确定</el-button>
        </div>
    </el-dialog>
</template>
<script>
import {queryAllClassifyGoods} from "@/api/maketing/coupons";
export default {
    props:{
        classifyAll:{ //是否类型选择，false为商品选择
            type:Boolean,
            default:false
        },
    },
    data(){
        return {
            selectGoodsArr:[],
            tabsArr:[{
                label:'生鲜商品',
                value:'first',
                goodsList:[] //该目录下的商品
            },{
                label:'丽日商品',
                value:'second',
                goodsList:[]
            },{
                label:'百货商品',
                value:'third',
                goodsList:[]
            }],//商品大类
            currTab:0, //当前tabs标题的位置
            goodsList:[],//商品类别
            showGoods:false,
            keyword:"",//查询的商品
            activeName: 'first',
            firstIdGroup:[], //一级分类所选择的列表 ID
            secondIdGroup:[],//二级分类所选择的类别 ID
            threeSelectGoodsIDGroup:[], //已选择的商品类别 ID

            firstSelectArr:[], //一级选择后的数据 包含所有字段的数据
            secondSelectArr:[],//二级分类选择的数据 包含所有字段的数据
            threeSelectGoodsArr:[], //商品选择的数据包 含所有字段的数据
            
            secondList:[], //一级分类下所有类目，一级分类的children
            threeList:[], //二级分类下所有的商品列表， 二级分类的children
            props:{
                multiple:true,
                value:"goodsId",
            },
            checkboxArr:[],       
            isSelectAll:false,
            selectAllStatus:0,
            firstIndex:'',
            count: 0,//商品懒加载数
            maxCount:0,//最大加载数
            filterText:'',
            selCount:0,//已选择商品懒加载
            // checkedThreeList:[],//已选择的商品
        }
    },
    watch:{
        showGoods(val){
            if(val){
                this.secondList=[]
                this.threeList =[]
            }
        },
        threeSelectGoodsArr(val){
            if(val.length>14){
                this.selCount = 14;
            }else{
                this.selCount = val.length;
            }
        },
    },
    methods:{
        load () {
            if(this.threeList.length===0 ||this.count<9){
                return ;
            }
            if(this.count < this.threeList.length){
                if(this.count+4>this.threeList.length){
                    this.count+=this.threeList.length - this.count
                }else{
                    this.count += 4
                }
            }
        },
        selLoad(){
            if(this.threeSelectGoodsArr.length===0 ||this.selCount<14){
                return ;
            }
            if(this.selCount < this.threeSelectGoodsArr.length){
                if(this.selCount+4>this.threeSelectGoodsArr.length){
                    this.selCount+=this.threeSelectGoodsArr.length - this.selCount
                }else{
                    this.selCount += 4
                }
            }
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 关闭弹窗
        close() {
            this.showGoods = false;
        },
        // 搜索
        search() {
            this.pageNum = 1;
            this.pageSize = 5;
            this.getAllClassifyGoods();
        },
        // 刷新
        refresh() {
            this.search();
        },
        // 取消勾选
        cancelSelect() {
            this.showGoods = false;
        },
        removeTag(id){
            this.changeThreeSelect(id)
            this.showThree(this.threeList)
        },
        // 查询所有分类及商品
        async getAllClassifyGoods(){
            // this.tabsArr=[]]
            let goodsType = this.currTab + 1
            const res = await queryAllClassifyGoods(this.keyword,goodsType)
            this.goodsList = res.data.body
            this.tabsArr[this.currTab].goodsList = res.data.body[this.currTab].map
            this.activeName = this.activeName?this.activeName:this.tabsArr[0].value
            this.secondList=[]
            this.threeList =[]
        },  
        //切换tabs
        handleClick(tab){
            this.currTab =parseInt(tab.index)
            this.getAllClassifyGoods() //1.生鲜商品 2.丽日商品 3.百货商品
            this.secondList=[]
            this.threeList =[]
            this.firstIndex = ''
        },
        // 选中商品发生改变时
        changeGoods(data){
            console.log(data);
        },
        changeFirstSelect(data,item){//一级
            let id= parseInt(data.toString().replace(/^\-/g,''))
            let idx = this.firstIdGroup.indexOf(id)
            if(idx===-1){
                this.firstIdGroup.push(id)
            }else{
                this.firstIdGroup.splice(idx,1)
            }
        },
        changeSecondSelect(data,item){ //二级
            let id= parseInt(data.toString().replace(/^\-/g,''))
            let idx = this.secondIdGroup.indexOf(id)
            let hasSel = data.toString().search(/^\-/g); //是否选中
            let secondGoodsIds = item.children.map(item => item['goodsId'])
            
            if(hasSel===-1){ //批量添加
                this.threeSelectGoodsArr = this.threeSelectGoodsArr.concat(item.children)
                this.threeSelectGoodsIDGroup = this.threeSelectGoodsIDGroup.concat(secondGoodsIds)
            }else{
                //删除ID
                this.threeSelectGoodsIDGroup = this.threeSelectGoodsIDGroup.filter((x) => {
                    return secondGoodsIds.indexOf(x) ===-1
                }); 
                // 删除选择的数据
                this.threeSelectGoodsArr = this.threeSelectGoodsArr.filter((x)=>{
                    return !secondGoodsIds.some((obj) => x.goodsId === obj)
                })
            }
            
            if(idx===-1){
                this.secondIdGroup.push(id)
                
            }else{
                this.secondIdGroup.splice(idx,1)
            }
            this.showThree(this.threeList)
        },
        changeThreeSelect(id){ //商品添加
            
             id= parseInt(id.toString().replace(/^\-/g,''))
            let curGoodsIds = this.threeList.map(item => item['goodsId'])
            let idInGroup = this.threeSelectGoodsIDGroup.indexOf(id)  //判断是否已选择
            
            let item = this.threeList[curGoodsIds.indexOf(id)]
            if(idInGroup===-1){
                this.threeSelectGoodsIDGroup.push(id)
                this.threeSelectGoodsArr.push(item)
            }else{
                this.threeSelectGoodsIDGroup.splice(idInGroup,1)
                this.threeSelectGoodsArr.splice(idInGroup,1)
            }
            this.selectAllStatus = this.hasGoodsId(this.threeList) 
            if(this.selectAllStatus===1){
                this.isSelectAll = true
            }
        },
        showSecond(data,index){ //显示二级分类
            this.firstIndex = index;
            this.count = 0
            this.threeList = [];
            this.isSelectAll = false;
            this.$nextTick(()=>{
                 this.secondList=data
            })
           
        },
        showThree(data){//显示商品列表
            this.isSelectAll = false;
            this.threeList = []; //先清空
            this.$nextTick(()=>{
                if(data.length>9){
                    this.count = 9;
                }else{
                    this.count = data.length;
                }
                this.threeList=data //显示页面和筛选条件
            })
            
            this.selectAllStatus = this.hasGoodsId(data) 
        },
        // 发送所选的商品
        submitGoods(){
            let obj = {
                goodsListId: this.threeSelectGoodsIDGroup,
                goodsList: this.threeSelectGoodsArr
            }
            this.$emit('getGoodsList',obj)
            this.showGoods = false
        },
        selectAll(bool){ //选择所有
            // console.log(this.isSelectAll)
            // this.isSelectAll = !this.isSelectAll
            let arr = JSON.parse(JSON.stringify(this.threeList))
            arr.forEach(item=>{
                let id= item.goodsId
                let idx = this.threeSelectGoodsIDGroup.indexOf(id)
                if(idx===-1){
                    if(bool){
                    // if(this.isSelectAll){
                        this.threeSelectGoodsIDGroup.push(id)
                        this.threeSelectGoodsArr.push(item)
                    }
                }else{
                    if(!bool){
                        this.threeSelectGoodsIDGroup.splice(idx,1)
                        this.threeSelectGoodsArr.splice(idx,1)
                    }
                }
            })
            this.threeList = []; //先清空
            this.$nextTick(()=>{
                this.threeList=arr //显示页面和筛选条件
            })  
            
            // this.showThree(this.threeList)
        },
        // 判断二级类目是否含有选中的商品id
        hasGoodsId(data){
            let curGoodsIds = data.map(item => item['goodsId'])
            let newList = curGoodsIds.filter((val) => {
                    return this.threeSelectGoodsIDGroup.indexOf(val)>-1
                })
            if(curGoodsIds.length!=0&&newList.length === curGoodsIds.length){ //所有的商品都选择了
                return 1
            }else if(newList.length>0&&newList.length<curGoodsIds.length){ //选择了部分商品
                return 2
            }else{ //没有选择
                return 0
            }
           
        }
    }
}
</script>
<style lang="scss" scoped>
    .selList{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .infinite-list{
        overflow-y:auto;
        height:412px;
        margin:0;
        padding:0;
        margin-top:10px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(144,147,153,.3);
        }
        .infinite-list-item{
            list-style: none;
            height:30px;
            line-height:30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:0 10px;
            &:hover{
                color:#409EFF;
            }
            span{
                width:180px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            .del-icon{
                cursor: pointer;
                &:hover{
                    color:#F56C6C;
                }
            }
        }
    }
    .goods_wrap{
        display: flex;
        .left{
            flex: 1;
        }
        .right{
            width:240px;
            height: 466px;
            border-radius: 4px;
            border: 1px solid #DCDFE6;
            overflow: hidden;
            margin-left: 10px;
            padding: 10px;
            h3{
                font-size:16px;
                margin:0;
                height:32px;
                line-height:32px;
            }
        }
        .top{
            width:100%;
            height:70px;
            border-radius: 4px;
            border:1px solid #DCDFE6;
            overflow:hidden;
            margin-bottom:10px;
            padding:10px;
            /deep/.el-tag{
                margin-right:5px;
                margin-bottom:5px;
            }
            
        }
    }
    .goods-list{
        height: 345px; 
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(144,147,153,.3);
        }
    }
    .bottom{
        .select-wrap{
            width:100%;
            height:380px;
            display: flex;
            .title{
                height:34px;
                line-height:34px;
                width:100%;
                border-bottom:1px solid #DCDFE6;
                padding-left:10px;
                font-size:14px;
            }
            .box{
                height:100%;
                overflow:hidden;
                border-radius: 4px;
                border:1px solid #DCDFE6;
                ul,li{
                    margin:0;
                    list-style:none;
                    padding:0;
                }
                li{
                    padding-left:10px;
                    display: flex;
                    &:hover{
                        color:#409EFF;
                    }
                    &.is-active{
                        .label{
                           color:#409EFF;
                        }
                    }
                    i{
                        float:right;
                        height:30px;
                        line-height:30px;
                        margin-right:10px;
                    }
                    .label{
                        flex: 1;
                        height:30px;
                        line-height:30px;
                        cursor: pointer;
                        span{
                            display: inline-block;
                            width:145px;
                            overflow:hidden;
                            text-overflow:ellipsis;
                            white-space:nowrap;
                        }
                    }
                }
                /deep/.el-checkbox{
                    // width:100%;
                    height:30px;
                    line-height:30px;
                    // padding-left:10px;
                    margin-right:5px;
                }
                .three_checkbox{
                    .el-checkbox{
                    display: block;
                    }
                }
            }
            .first{
                width:200px;
                margin-right:8px;
            }
            .second{
                width:200px;
                margin-right:8px;
            }
            .three{
                flex:1;
                max-width:400px;
                .title{
                    display: flex;
                    justify-content: space-between;
                    padding-right:10px;
                }
            }
        }
    }
</style>